/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
	const { user } = useSelector((state) => state.auth);
	const intl = useIntl();
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					"menu-item-active"} menu-item-open menu-item-not-hightlighted`
			: "";
	};

		return (
			<>
				{/* begin::Menu Nav */}
				<ul className={`menu-nav ${layoutProps.ulClasses}`}>

					{/* begin::section */}
					<li className="menu-section ">
						<h4 className="menu-text">{ intl.formatMessage({ id: "Content" }) }</h4>
						<i className="menu-icon flaticon-more-v2"></i>
					</li>
					{/* end:: section */}
					<li
						className={`menu-item ${getMenuItemActive("/inventory", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/inventory">
							<span className="svg-icon menu-icon">
								<SVG src={ "/svg/box.svg" }  />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Inventory" }) }</span>
						</NavLink>
					</li>

					{ user.type == 0 &&
						<>
							{/* begin::section */}
							<li className="menu-section ">
								<h4 className="menu-text">{ intl.formatMessage({ id: "User" }) }</h4>
								<i className="menu-icon flaticon-more-v2"></i>
							</li>
							{/* end:: section */}
							<li
								className={`menu-item ${getMenuItemActive("/user", false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/user">
									<span className="svg-icon menu-icon">
										<SVG src={ "/svg/user.svg" }  />
									</span>
									<span className="menu-text">{ intl.formatMessage({ id: "App User" }) }</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive("/admin", false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin">
									<span className="svg-icon menu-icon">
										<SVG src={ "/svg/admin.svg" } />
									</span>
									<span className="menu-text">{ intl.formatMessage({ id: "CMS User" }) }</span>
								</NavLink>
							</li>
						</>
					}
				</ul>

				{/* end::Menu Nav */}
			</>
		);


}
