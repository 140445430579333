import { createSlice } from "@reduxjs/toolkit";

export const callTypes = {
	list: "list",
	action: "action"
};

const initState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	lastError: null,
	item: {
		"text_files": [],
		"photo_files": [],
		"audio_files": [],
		"video_files": [],
		"remove_photo": [],
		"remove_audio": [],
		"remove_video": [],
		"remove_text_note": []
	}
};

export const slice = createSlice({
	name: "project",
	initialState: initState,
	reducers: {
		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},
		fetched: (state, action) => {
			const { totalCount, entities } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = entities;
			state.totalCount = totalCount;
		},
		fetchedItem: (state, action) => {
			state.actionsLoading = false;
			if (action.payload.item) {
				state.item = action.payload.item;
			} else {
				state.item = initState.item;
			}
			state.next = action.payload.next
			state.prev = action.payload.prev
			state.item.text_files = []
			state.item.photo_files = []
			state.item.audio_files = []
			state.item.video_files = []
			state.item.remove_photo = []
			state.item.remove_audio = []
			state.item.remove_video = []
			state.item.remove_text_note = []
			state.error = null;
		},
		created: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		updated: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		rotated: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		reset: (state, action) => {
			state.actionsLoading = false;
			state.item = initState.item
			state.error = null;
		},
		deleteItem: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		exportAll: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		exportItem: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		updatedItem: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		}
	}
})