import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/v1/cms/auth/login`;
export const GET_USER_URL = `${process.env.REACT_APP_API_URL}/v1/cms/auth/getUser`;

export function login(username, password) {
	return axios.post(LOGIN_URL, { username, password });
}

export function getUserByToken() {
	// Authorization head should be fulfilled in interceptor.
	return axios.get(GET_USER_URL);
}
