import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector } from "react-redux";

const Inventory = lazy(() => import("./pages/Inventory/Page"));
const User = lazy(() => import("./pages/User/Page"));
const Admin = lazy(() => import("./pages/Admin/Page"));

export default function BasePage() {
	const { user } = useSelector((state) => state.auth);

	// useEffect(() => {
	//	 console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from="/" to="/inventory" />
				}
				<ContentRoute path="/inventory" component={Inventory} />
				<ContentRoute path="/user" component={User} />
				<ContentRoute path="/admin" component={Admin} />
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
}
