import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { slice as inventory } from "../app/pages/Inventory/reducer";
import { slice as user } from "../app/pages/User/reducer";
import { slice as admin } from "../app/pages/Admin/reducer";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	inventory: inventory.reducer,
	user: user.reducer,
	admin: admin.reducer
});

export function* rootSaga() {
	yield all([auth.saga()]);
}
